export const environment = {
    name: "qa",
    production: false,
    rootAuthnUrl: "https://qa.authn.spectrumreach.io",
    adminPermissionName: "adminUIAccess",
    cognito: {
        customProviderName: "ESSO",
        Auth: {
            region: "us-east-1",
            userPoolId: "us-east-1_FnrvCfFjd",
            userPoolWebClientId: "1mof3i0h84bd55vankto2n33n6",
        },
        oauth: {
            domain: "srd-users-qa.auth.us-east-1.amazoncognito.com",
            redirectSignIn: "https://qa.admin.spectrumreach.io/",
            redirectSignOut: "https://qa.admin.spectrumreach.io/",
            responseType: "token",
        }
    },
    allowCognitoAuth: true
};